import {Promise} from 'es6-promise';
import Deferred from 'es6-deferred';

try {
    if (typeof window.Promise === 'undefined'){
        window.Promise = Promise;
    }
    if (typeof window.Deferred === 'undefined'){
        window.Deferred = Deferred;
    }
}
catch (e) {
    console.error('thinkpozitive.js', e);
}
window.libraryPromises = {
    jQueryDeferred: new Deferred(),
    bootstrapDeferred: new Deferred()
};

import ('jquery').then((jqueryComponent) => {
    //Declare jQuery to be globally accessible
    window.$ = window.jQuery = jqueryComponent.default;
    window.libraryPromises.jQueryDeferred.resolve();
    import('popper.js').then((Popper) => {
        //Declare Popper to be globally accessible
        window.Popper = Popper.default;
        import('bootstrap').then(() => {
            window.libraryPromises.bootstrapDeferred.resolve();
        });
    });
});

import('@fortawesome/fontawesome-pro/js/all.min');